import Nav from "../components/Nav";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import * as API from "../api";

const Admin = () => {
    const ctx = useOutletContext();
    const navigate = useNavigate();

    const [mobilePercentage, setMobilePercentage] = useState(0);
    const [desktopPercentage, setDesktopPercentage] = useState(0);

    const [signedInPercentage, setSignedInPercentage] = useState(0);
    const [guestPercentage, setGuestPercentage] = useState(0);

    useEffect(() => {
        (async () => {
            ctx.setAdminLoaded(false);

            try {
                if (!ctx.user.hasOwnProperty("id") || !ctx.user.is_admin) {
                    ctx.setAdminLoaded(true);
                    navigate("/");
                    return;
                }

                if (!ctx.adminLoaded) {
                    await API.fetchAdminAnalytics(ctx.user, ctx.setAdminAnalytics);
                }
            } catch (error) { }

            ctx.setAdminLoaded(true);
        })();
    }, [ctx.user, navigate]);

    useEffect(() => {
        if (!ctx.adminLoaded || !ctx.adminAnalytics.success) {
            return;
        }

        const cMobilePercentage = Math.ceil(ctx.adminAnalytics.device_ratio.mobile_count / ctx.adminAnalytics.record_count * 100).toFixed(0);
        setMobilePercentage(cMobilePercentage);
        setDesktopPercentage((100 - cMobilePercentage).toFixed(0));

        const cSignedInPercentage = Math.ceil(ctx.adminAnalytics.sign_in_ratio.signed_in_count / ctx.adminAnalytics.record_count * 100).toFixed(0);
        setSignedInPercentage(cSignedInPercentage);
        setGuestPercentage((100 - cSignedInPercentage).toFixed(0));
    }, [ctx.adminLoaded]);

    if (!ctx.loaded || !ctx.adminLoaded)
        return <></>;

    return (
        <div className="flex h-full justify-center p-2 bg-gradient-to-b from-violet-600/10 via-transparent">
            <div className="w-[64rem] h-full">
                <Nav loaded={ctx.loaded} setLoaded={ctx.setLoaded} user={ctx.user} setUser={ctx.setUser} />

                <div className="mt-4 flex items-center">
                    <div className="w-full flex flex-col border shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
                        <div className="p-4">
                            <h3 className="text-lg font-medium text-white">Admin panel</h3>

                            <div className="my-4">
                                <span className="font-medium text-sm text-white">Cache updated at {new Date(ctx.adminAnalytics.last_updated).toLocaleString()}</span>
                            </div>

                            <div className="flex flex-col gap-6">
                                <div className="flex flex-col gap-2">
                                    <span className="font-medium text-sm text-white">Device ratio</span>

                                    <div class="flex w-full h-8 bg-gray-200 rounded-lg overflow-hidden dark:bg-neutral-700">
                                        <div class={`flex flex-col justify-center overflow-hidden ${(desktopPercentage < mobilePercentage) ? "bg-gray-600" : "bg-gray-700"} text-sm text-white text-center whitespace-nowrap`} style={{ "width": `${desktopPercentage}%` }} role="progressbar" aria-valuenow={desktopPercentage} aria-valuemin="0" aria-valuemax="100">
                                            <span className="font-medium text-sm text-white">Desktop: {desktopPercentage}%</span>
                                        </div>
                                        <div class={`flex flex-col justify-center overflow-hidden ${(desktopPercentage >= mobilePercentage) ? "bg-gray-600" : "bg-gray-700"} text-sm text-white text-center whitespace-nowrap`} style={{ "width": `${mobilePercentage}%` }} role="progressbar" aria-valuenow={mobilePercentage} aria-valuemin="0" aria-valuemax="100">
                                            <span className="font-medium text-sm text-white">Mobile: {mobilePercentage}%</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <span className="font-medium text-sm text-white">Sign in ratio</span>

                                    <div class="flex w-full h-8 bg-gray-200 rounded-lg overflow-hidden dark:bg-neutral-700">
                                        <div class={`flex flex-col justify-center overflow-hidden ${(signedInPercentage < guestPercentage) ? "bg-gray-600" : "bg-gray-700"} text-sm text-white text-center whitespace-nowrap`} style={{ "width": `${signedInPercentage}%` }} role="progressbar" aria-valuenow={signedInPercentage} aria-valuemin="0" aria-valuemax="100">
                                            <span className="font-medium text-sm text-white">Signed in: {signedInPercentage}%</span>
                                        </div>
                                        <div class={`flex flex-col justify-center overflow-hidden ${(signedInPercentage >= guestPercentage) ? "bg-gray-600" : "bg-gray-700"} text-sm text-white text-center whitespace-nowrap`} style={{ "width": `${guestPercentage}%` }} role="progressbar" aria-valuenow={guestPercentage} aria-valuemin="0" aria-valuemax="100">
                                            <span className="font-medium text-sm text-white">Guest: {guestPercentage}%</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <span className="font-medium text-sm text-white">Top countries: {ctx.adminAnalytics.top_countries.map(e => e.cf_country).join(", ")}</span>
                                    <span className="font-medium text-sm text-white">Top CPU thread counts: {ctx.adminAnalytics.top_cpu_thread_counts.map(e => e.cpu_threads).join(", ")}</span>
                                    <span className="font-medium text-sm text-white">Top screen infos: {ctx.adminAnalytics.top_screen_infos.map(e => e.screen_info).join(", ")}</span>
                                    <span className="font-medium text-sm text-white">Top timezones: {ctx.adminAnalytics.top_timezone_names.map(e => e.timezone_name).join(", ")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Admin;
