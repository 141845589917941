import Nav from "../components/Nav";
import { useNavigate, useOutletContext } from "react-router-dom";
import * as API from "../api";
import Products from "../components/Products";

const Store = () => {
  const ctx = useOutletContext();
  const navigate = useNavigate();

  const purchasePayPal = async () => {
    try {
      await API.purchasePayPal(ctx.user, ctx.setUser);
      navigate("/dashboard");
    } catch (error) { }
  };

  const purchaseCrypto = async () => {
    try {
      await API.purchasePayPal(ctx.user, ctx.setUser);
      navigate("/dashboard");
    } catch (error) { }
  };

  if (!ctx.loaded) return <></>;

  return (
    <div className="flex h-full justify-center p-2 bg-gradient-to-b from-violet-600/10 via-transparent">
      <div className="w-[64rem] h-full">
        <Nav
          loaded={ctx.loaded}
          setLoaded={ctx.setLoaded}
          user={ctx.user}
          setUser={ctx.setUser}
        />

        <Products />
      </div>
    </div>
  );
};

export default Store;
