import {
  IconBrandDiscordFilled,
  IconChevronDown,
  IconChevronUp,
} from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const discordConfig = {
  clientId: "1280135780550836275",
  redirect: `${window.location.origin}/sign-in`,
};

const UserDropdown = ({ user, setUser }) => {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();

  const LogoutDiscord = async () => {
    localStorage.removeItem("accessToken");
    setUser({});
    navigate("/");
  };

  return (
    <>
      <div className="relative flex flex-col">
        <button
          type="button"
          className={`py-2 px-4 inline-flex items-center gap-x-2 text-sm ${active ? "rounded-t-lg" : "rounded-lg"
            } bg-neutral-800 border border-neutral-700 text-white`}
          onClick={() => setActive(!active)}
        >
          <img
            className="inline-block size-6 rounded-full"
            src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`}
            alt=""
          />
          <span>{user.global_name}</span>
          {active ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
        </button>

        <div
          className={`${active ? "opacity-100" : "opacity-0"
            } absolute top-[2.5rem] w-full z-20 transition ease-in-out delay-25 bg-neutral-800 shadow-md rounded-b-lg p-2 border border-neutral-700 divide-neutral-700`}
        >
          <button
            className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm"
            onClick={() => navigate("/dashboard")}
            disabled={!active}
          >
            Dashboard
          </button>

          {user.is_admin ? (
            <>
              <button
                className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm"
                onClick={() => navigate("/admin")}
                disabled={!active}
              >
                Admin panel
              </button>
            </>
          ) : (<></>)}
          <button
            className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-red-500"
            onClick={LogoutDiscord}
            disabled={!active}
          >
            Log out
          </button>
        </div>
      </div>
    </>
  );
};

const Nav = ({ loaded, setLoaded, user, setUser }) => {
  const navigate = useNavigate();

  const SignInWithDiscord = async () => {
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${discordConfig.clientId
      }&redirect_uri=${encodeURIComponent(
        discordConfig.redirect
      )}&response_type=token&scope=identify+email`;
  };

  return (
    <div className="bg-white/10 border-white/20 text-white border border-gray-200 text-sm rounded-lg p-3">
      <div className="flex items-center justify-between h-[2rem]">
        <button
          type="button"
          className="py-2 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg bg-neutral-900/40 text-white"
          onClick={() => navigate("/")}
        >
          <img width={18} src="/assets/logo.png" alt=""></img> eclypse.cc
        </button>
        <div className="flex justify-end">
          {user.hasOwnProperty("id") ? (
            <>
              <UserDropdown user={user} setUser={setUser} />
            </>
          ) : (
            <>
              <button
                type="button"
                className="py-2 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg bg-[#5865F2] text-white"
                onClick={SignInWithDiscord}
              >
                <IconBrandDiscordFilled size={18} className="mr-[-0.25rem]" />{" "}
                Sign in with Discord
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Nav;
