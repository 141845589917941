import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white/60 backdrop-blur-lg dark:bg-neutral-900/60">
      <div className="max-w-[85rem] px-4 py-4 sm:px-6 lg:px-8 mx-auto">
        <div className="grid justify-center sm:grid-cols-2 sm:items-center gap-4">
          <div className="flex items-center gap-x-3 md:gap-x-5">
            <img width={48} src="/assets/logo.png" alt=""></img>

            <div className="grow">
              <p className="md:text-xl text-gray-800 font-semibold dark:text-neutral-200">
                Get{" "}
                <span className="bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                  eclypse.cc
                </span>{" "}
                today
              </p>
              <p className="text-sm md:text-base text-gray-800 dark:text-neutral-200">
                Sign up and purchase to get access.
              </p>
            </div>
          </div>

          <div className="text-center sm:text-start flex sm:justify-end sm:items-center gap-x-3 md:gap-x-4">
            <button className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-gray-800 text-gray-800 hover:border-gray-500 hover:text-gray-500 focus:outline-none focus:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:border-white dark:text-white dark:hover:text-neutral-300 dark:hover:border-neutral-300 dark:focus:text-neutral-300 dark:focus:border-neutral-300">
              Ask for a trial
            </button>
            <button
              className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-transparent bg-gradient-to-tl from-blue-600 to-violet-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              onClick={() => navigate("/store")}
            >
              Buy now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
