import { useNavigate } from "react-router-dom";

const Stepper = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-neutral-900">
      <div className="max-w-5xl px-4 xl:px-0 py-10 lg:pt-20 lg:pb-20 mx-auto">
        <div className="max-w-3xl mb-10 lg:mb-14">
          <h2 className="text-white font-semibold text-2xl md:text-4xl md:leading-tight">
            Get started with{" "}
            <span className="bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
              eclypse.cc
            </span>
          </h2>
          <p className="mt-1 text-neutral-400">
            Want to start dominating your games? Wait no longer — we offer the
            easiest and most secure ways to get started. Follow these simple
            steps and rise straight to the top.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-16 lg:items-center">
          <div className="aspect-w-16 aspect-h-9 lg:aspect-none">
            <img
              className="w-full object-cover rounded-xl"
              src="/assets/cs2-vertical.jpg"
              alt="Steps"
            />
          </div>
          <div>
            <div className="mb-4">
              <h3 className="bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent text-xs font-medium uppercase">
                How does it work
              </h3>
            </div>
            <div className="flex gap-x-5 ms-1">
              <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-neutral-800">
                <div className="relative z-10 size-8 flex justify-center items-center">
                  <span className="flex shrink-0 justify-center items-center size-8 border border-neutral-800 bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent font-semibold text-xs uppercase rounded-full">
                    1
                  </span>
                </div>
              </div>
              <div className="grow pt-0.5 pb-8 sm:pb-12">
                <p className="text-sm lg:text-base text-neutral-400">
                  <span className="text-white">Explore our products: </span>
                  <br></br>
                  Take a look at the store page to get a closer look at our
                  products and their features. More information can be found on
                  the product pages.
                </p>
              </div>
            </div>
            <div className="flex gap-x-5 ms-1">
              <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-neutral-800">
                <div className="relative z-10 size-8 flex justify-center items-center">
                  <span className="flex shrink-0 justify-center items-center size-8 border border-neutral-800 bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent font-semibold text-xs uppercase rounded-full">
                    2
                  </span>
                </div>
              </div>
              <div className="grow pt-0.5 pb-8 sm:pb-12">
                <p className="text-sm lg:text-base text-neutral-400">
                  <span className="text-white">Purchase a subscription: </span>
                  <br></br>
                  Select your preferred payment method and purchase a
                  subscription to start dominating your games with our cheats.
                </p>
              </div>
            </div>
            <div className="flex gap-x-5 ms-1">
              <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-neutral-800">
                <div className="relative z-10 size-8 flex justify-center items-center">
                  <span className="flex shrink-0 justify-center items-center size-8 border border-neutral-800 bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent font-semibold text-xs uppercase rounded-full">
                    3
                  </span>
                </div>
              </div>
              <div className="grow pt-0.5 pb-8 sm:pb-12">
                <p className="text-sm md:text-base text-neutral-400">
                  <span className="text-white">Setup the software: </span>
                  <br></br>
                  You can find a detailed guide on how to set up our software on
                  our Discord server. Make sure to follow every step carefully
                  or contact an admin if you need assistance.
                </p>
              </div>
            </div>
            <div className="flex gap-x-5 ms-1">
              <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-neutral-800">
                <div className="relative z-10 size-8 flex justify-center items-center">
                  <span className="flex shrink-0 justify-center items-center size-8 border border-neutral-800 bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent font-semibold text-xs uppercase rounded-full">
                    4
                  </span>
                </div>
              </div>
              <div className="grow pt-0.5 pb-8 sm:pb-12">
                <p className="text-sm md:text-base text-neutral-400">
                  <span className="text-white">Configure to your liking: </span>
                  <br></br>
                  Our cheats are highly customizable and can be tailored to fit
                  your needs to the maximum extent. Join our Discord to try our
                  customers configs or share your own.
                </p>
              </div>
            </div>

            <button
              className="group inline-flex items-center gap-x-2 py-2 px-3 bg-gradient-to-tl from-blue-600 to-violet-600 font-medium text-sm text-white rounded-full focus:outline-none"
              onClick={() => navigate("/store")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="shrink-0 size-4"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z"
                  strokeWidth="0"
                  fill="currentColor"
                />
              </svg>
              Start winning
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
