import Nav from "../components/Nav";
import { useNavigate, useOutletContext } from "react-router-dom";
import { IconDownload, IconRefresh } from "@tabler/icons-react";
import { useEffect } from "react";

const Dashboard = () => {
    const ctx = useOutletContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!ctx.user.hasOwnProperty("id")) {
            navigate("/");
            return;
        }
    }, [ctx.user, navigate]);

    if (!ctx.loaded)
        return <></>;

    return (
        <div className="flex h-full justify-center p-2 bg-gradient-to-b from-violet-600/10 via-transparent">
            <div className="w-[64rem] h-full">
                <Nav loaded={ctx.loaded} setLoaded={ctx.setLoaded} user={ctx.user} setUser={ctx.setUser} />

                <div className="mt-4 flex items-center">
                    <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
                        <div className="p-4">
                            <h3 className="text-lg font-medium text-white mb-4">Active subscriptions</h3>

                            {(!ctx.user.has_sub || (ctx.user.sub_ends_at < Date.now())) ?
                                <>
                                    <span className="font-medium text-red-500">You don't have any subscription.</span>
                                </>
                                :
                                <>
                                    <div className="flex flex-col md:flex-row gap-1 bg-white/10 rounded-xl items-center shadow-sm">
                                        <img className="w-full md:w-[16rem] rounded-l-xl md:rounded-l-xl" src="/assets/cs2.jpeg" alt="" />

                                        <div className="flex flex-col md:flex-row gap-1 items-center p-3 w-full">
                                            <div className="w-full md:w-1/2 p-4">
                                                <div className="flex flex-col gap-1">
                                                    <span className="font-medium text-white">Counter-Strike 2</span>
                                                    <span className="font-medium text-sm text-white">Expiration date: {(new Date(ctx.user.sub_ends_at).toLocaleString())}</span>

                                                    <button type="button" className="py-2 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg bg-green-600 text-black">
                                                        <IconDownload size={18} className="mr-[-0.25rem]" /> Download cheat
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="w-full md:w-1/2 p-4">
                                                <div className="flex flex-col gap-1">
                                                    <span className="font-medium text-sm text-white">Last updated: Today</span>
                                                    <span className="font-medium text-sm text-white">Latest detection: Never</span>

                                                    <button type="button" className="py-2 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg bg-blue-400 text-black">
                                                        <IconRefresh size={18} className="mr-[-0.25rem]" /> Renew subscription
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
