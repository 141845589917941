import Hero from "../components/Hero";
import { useOutletContext } from "react-router-dom";
import Stepper from "../components/Stepper";
import Footer from "../components/Footer";
import Features from "../components/Features";

const Home = () => {
  const ctx = useOutletContext();

  if (!ctx.loaded) return <></>;

  return (
    <>
      <Hero />
      <Features />
      <Stepper />
      <Footer />
    </>
  );
};

export default Home;
