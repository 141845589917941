const API_URL = `${window.location.origin}/api`;

export const fetchDiscordUser = async (setUser) => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
        const dcRequest = await fetch("https://discord.com/api/users/@me", {
            "headers": {
                "Authorization": `Bearer ${accessToken}`,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });

        const dcData = await dcRequest.json();

        if (dcData) {
            const apiRequest = await fetch(`${API_URL}/authenticate`, {
                "method": "POST",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                "body": JSON.stringify({
                    "discord_email": dcData.email,
                    "discord_user_id": dcData.id,
                    "discord_username": dcData.username,
                    "discord_access_token": accessToken
                })
            });

            const apiData = await apiRequest.json();

            if (apiData.success) {
                setUser({
                    ...dcData,
                    ...apiData
                });
            }
        }
    }
}

export const purchasePayPal = async (user, setUser) => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
        const apiRequest = await fetch(`${API_URL}/purchase`, {
            "method": "POST",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            "body": JSON.stringify({
                "discord_email": user.email,
                "discord_user_id": user.id,
                "discord_username": user.username,
                "discord_access_token": accessToken
            })
        });

        const apiData = await apiRequest.json();

        if (apiData.success) {
            setUser({
                ...user,
                "has_sub": apiData.has_sub,
                "sub_ends_at": apiData.sub_ends_at
            });
        }
    }
}

export const sendAnalytics = async (data) => {
    const apiRequest = await fetch(`${API_URL}/analytics`, {
        "method": "POST",
        "headers": {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        "body": JSON.stringify({
            "data": btoa(JSON.stringify(data))
        })
    });

    await apiRequest.json();
}

export const fetchAdminAnalytics = async (user, setAdminAnalytics) => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
        const apiRequest = await fetch(`${API_URL}/adminanalytics`, {
            "method": "POST",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            "body": JSON.stringify({
                "discord_email": user.email,
                "discord_user_id": user.id,
                "discord_username": user.username,
                "discord_access_token": accessToken
            })
        });

        const apiData = await apiRequest.json();

        if (apiData.success) {
            setAdminAnalytics(apiData);
        }
    }
}