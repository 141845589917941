import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const hash = window.location.hash;

        if (!hash) {
            navigate("/");
            return;
        }

        const params = new URLSearchParams(hash.substring(1));
        const accessToken = params.get("access_token");

        if (!accessToken) {
            navigate("/");
            return;
        }

        localStorage.setItem("accessToken", accessToken);
        navigate("/dashboard");
    }, [navigate]);

    return <></>;
}

export default SignIn;
