import "@fontsource/inter";
import "./index.css";

import "preline/dist/preline.js";

import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import App from "./App";
import Home from "./routes/Home";
import Store from "./routes/Store";
import SignIn from "./routes/SignIn";
import Dashboard from "./routes/Dashboard";
import Admin from "./routes/Admin";

const root = ReactDOM.createRoot(document.getElementById("root"));

const NoMatchRoute = () => {
  return <Navigate to={"/"} />
}

root.render(
  <BrowserRouter>
    <Routes>
      <Route element={<App />}>
        <Route path="/" element={<Home />} />
        <Route path="/store" element={<Store />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/admin" element={<Admin />} />
      </Route>
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="*" element={<NoMatchRoute />} />
    </Routes>
  </BrowserRouter>
);