import {
  IconBrandPaypalFilled,
  IconChartDots3,
  IconCoinBitcoinFilled,
  IconCoinFilled,
  IconCrosshair,
  IconEye,
  IconInfoCircleFilled,
  IconLock,
  IconTarget,
} from "@tabler/icons-react";

const Products = () => {
  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="group flex flex-col h-full bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
          <div className="h-52 flex flex-col justify-center items-center rounded-t-xl">
            <img
              className="h-full object-cover rounded-t-xl"
              src="/assets/cs2.jpeg"
              alt="Counter-Strike 2"
            />
          </div>
          <div className="p-4 md:p-6">
            <span className="block mb-1 text-xs font-semibold uppercase text-green-600 dark:text-green-500">
              Available
            </span>
            <h3 className="text-xl font-semibold text-gray-800 dark:text-neutral-300 dark:hover:text-white">
              Counter-Strike 2
            </h3>
            <p className="mt-3 text-gray-500 dark:text-neutral-500">
              A software that develops products for software developers and
              developments.
            </p>
          </div>
          <div className="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-neutral-700 dark:divide-neutral-700">
            <button className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-es-xl text-gray-800 shadow-md shadow-transparent hover:shadow-blue-600/50 disabled:opacity-50 disabled:pointer-events-none dark:bg-[#0079C1] dark:border-neutral-700 dark:text-white">
              <IconBrandPaypalFilled size={18} className="mr-[-0.25rem]" />{" "}
              PayPal
            </button>
            <button className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-ee-xl text-gray-800 shadow-md shadow-transparent hover:shadow-orange-500/50 disabled:opacity-50 disabled:pointer-events-none dark:bg-[#F7931A] dark:border-neutral-700 dark:text-black">
              <IconCoinBitcoinFilled size={18} className="mr-[-0.25rem]" />{" "}
              Crypto
            </button>
          </div>
        </div>
        <div className="group flex flex-col h-full bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
          <div className="h-52 flex flex-col justify-center items-center rounded-t-xl">
            <img
              className="h-full object-cover rounded-t-xl"
              src="/assets/valorant.webp"
              alt="Valorant"
            />
          </div>
          <div className="p-4 md:p-6">
            <span className="block mb-1 text-xs font-semibold uppercase text-amber-500">
              Coming soon
            </span>
            <h3 className="text-xl font-semibold text-gray-800 dark:text-neutral-300 dark:hover:text-white">
              Valorant
            </h3>
            <p className="mt-3 text-gray-500 dark:text-neutral-500">
              Track tasks and projects, use agile boards, measure progress.
            </p>
          </div>
          <div className="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-neutral-700 dark:divide-neutral-700">
            <button
              className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-es-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-white/10 dark:hover:bg-white/20 dark:border-neutral-700 dark:text-white"
              disabled=""
              aria-haspopup="dialog"
              aria-expanded="false"
              aria-controls="hs-bg-gray-on-hover-cards"
              data-hs-overlay="#hs-bg-gray-on-hover-cards"
            >
              <IconInfoCircleFilled size={18} className="mr-[-0.25rem]" />
              More info
            </button>

            <div
              id="hs-bg-gray-on-hover-cards"
              className="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
              role="dialog"
              tabIndex="-1"
              aria-labelledby="hs-bg-gray-on-hover-cards-label"
            >
              <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-4xl sm:w-full m-3 h-[calc(100%-3.5rem)] sm:mx-auto">
                <div className="max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-900 dark:border-neutral-800 dark:shadow-neutral-700/70">
                  <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-800">
                    <h3
                      id="hs-bg-gray-on-hover-cards-label"
                      className="font-bold text-gray-800 dark:text-neutral-200"
                    >
                      <span className="bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                        eclypse.cc
                      </span>{" "}
                      for Counter-Strike 2
                    </h3>
                    <button
                      type="button"
                      className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                      aria-label="Close"
                      data-hs-overlay="#hs-bg-gray-on-hover-cards"
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                      </svg>
                    </button>
                  </div>

                  <div className="p-4 overflow-y-auto">
                    <div className="sm:divide-y divide-gray-200 dark:divide-neutral-700">
                      <div className="py-3 sm:py-6">
                        <h4 className="mb-2 text-xs font-semibold uppercase text-gray-600 dark:text-neutral-400">
                          Features
                        </h4>

                        <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-3">
                          <a
                            className="bg-white p-4 transition duration-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="../docs/frameworks-vuejs.html"
                          >
                            <div className="flex gap-x-6">
                              <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl">
                                <IconTarget size={30} color="lightgray" />
                              </div>

                              <div className="grow">
                                <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                  Humanized Aimbot
                                </h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-neutral-500">
                                  The best and most humanlike aimbot on the
                                  market.
                                </p>
                              </div>
                            </div>
                          </a>

                          <a
                            className="bg-white p-4 transition duration-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="../docs/frameworks-vuejs.html"
                          >
                            <div className="flex gap-x-6">
                              <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl">
                                <IconChartDots3 size={30} color="lightgray" />
                              </div>

                              <div className="grow">
                                <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                  Recoil Control System
                                </h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-neutral-500">
                                  Control your recoil like a real pro with our
                                  RCS.
                                </p>
                              </div>
                            </div>
                          </a>

                          <a
                            className="bg-white p-4 transition duration-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="../docs/frameworks-vuejs.html"
                          >
                            <div className="flex gap-x-6">
                              <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl">
                                <IconCrosshair size={30} color="lightgray" />
                              </div>

                              <div className="grow">
                                <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                  Smart Triggerbot
                                </h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-neutral-500">
                                  Improve your reaction time with our precise
                                  triggerbot.
                                </p>
                              </div>
                            </div>
                          </a>

                          <a
                            className="bg-white p-4 transition duration-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="../docs/frameworks-vuejs.html"
                          >
                            <div className="flex gap-x-6">
                              <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl">
                                <IconEye size={30} color="lightgray" />
                              </div>

                              <div className="grow">
                                <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                  Player ESP {""}
                                  <span className="ms-1 inline bg-blue-50 border border-blue-300 text-blue-600 text-[.6125rem] leading-4 uppercase rounded-full py-0.5 px-2 dark:bg-blue-900/70 dark:border-blue-700 dark:text-blue-500">
                                    Updated ✨
                                  </span>
                                </h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-neutral-500">
                                  Know your enemy's location at all times.
                                </p>
                              </div>
                            </div>
                          </a>

                          <a
                            className="bg-white p-4 transition duration-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="../docs/frameworks-vuejs.html"
                          >
                            <div className="flex gap-x-6">
                              <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl">
                                <IconLock size={30} color="lightgray" />
                              </div>

                              <div className="grow">
                                <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                  Highest level of security
                                </h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-neutral-500">
                                  Never worry about getting banned again.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>

                      <div className="py-3 sm:py-6">
                        <h4 className="mb-2 text-xs font-semibold uppercase text-gray-600 dark:text-neutral-400">
                          Supported Anti-Cheats
                        </h4>

                        <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-3">
                          <a
                            className="bg-white p-4 transition duration-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="../docs/frameworks-vuejs.html"
                          >
                            <div className="flex gap-x-6">
                              <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl w-10 h-10">
                                <img className="rounded-lg" src="/assets/vac.png" alt=""></img>
                              </div>

                              <div className="grow">
                                <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                  VAC & VACnet
                                </h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-neutral-500">
                                  Stay undetected on Valve servers.
                                </p>
                              </div>
                            </div>
                          </a>

                          <a
                            className="bg-white p-4 transition duration-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="../docs/frameworks-vuejs.html"
                          >
                            <div className="flex gap-x-6">
                              <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl w-10 h-10">
                                <img className="rounded-lg" src="/assets/eac.png" alt=""></img>
                              </div>

                              <div className="grow">
                                <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                  EasyAntiCheat
                                </h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-neutral-500">
                                  Play without fear even on the most advanced
                                  Anti-Cheats.
                                </p>
                              </div>
                            </div>
                          </a>

                          <a
                            className="bg-white p-4 transition duration-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="../docs/frameworks-vuejs.html"
                          >
                            <div className="flex gap-x-6">
                              <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl w-10 h-10">
                                <img className="rounded-lg" src="/assets/esportal.jpg" alt=""></img>
                              </div>

                              <div className="grow">
                                <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                  ESPORTAL
                                </h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-neutral-500">
                                  Work your way up the ranks with our cheats.
                                </p>
                              </div>
                            </div>
                          </a>

                          <a
                            className="bg-white p-4 transition duration-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="../docs/frameworks-vuejs.html"
                          >
                            <div className="flex gap-x-6">
                              <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl w-10 h-10">
                                <img className="rounded-lg" src="/assets/akros.jpg" alt=""></img>
                              </div>

                              <div className="grow">
                                <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                  Akros Anti-Cheat{" "}
                                  <span className="ms-1 inline bg-blue-50 border border-blue-300 text-blue-600 text-[.6125rem] leading-4 uppercase rounded-full py-0.5 px-2 dark:bg-green-900/70 dark:border-green-700 dark:text-green-500">
                                    New
                                  </span>
                                </h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-neutral-500">
                                  Facing challenges have never been easier.
                                </p>
                              </div>
                            </div>
                          </a>

                          <a
                            className="bg-white p-4 transition duration-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="../docs/frameworks-vuejs.html"
                          >
                            <div className="flex gap-x-6">
                              <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl w-10 h-10">
                                <img className="rounded-lg" src="/assets/fastcup.png" alt=""></img>
                              </div>

                              <div className="grow">
                                <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                  GameGuard
                                </h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-neutral-500">
                                  Play FastCups like never before.
                                </p>
                              </div>
                            </div>
                          </a>

                          <a
                            className="bg-white p-4 transition duration-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            href="../docs/frameworks-vuejs.html"
                          >
                            <div className="flex gap-x-6">
                              <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl">
                                <svg
                                  className="size-5 text-gray-800 dark:text-neutral-200"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"></path>
                                  <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v2H1V3a1 1 0 0 1 1-1h12zM1 13V6h4v8H2a1 1 0 0 1-1-1zm5 1V6h9v7a1 1 0 0 1-1 1H6z"></path>
                                </svg>
                              </div>

                              <div className="grow">
                                <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                  More...{" "}
                                  <span className="ms-1 inline bg-blue-50 border border-blue-300 text-blue-600 text-[.6125rem] leading-4 uppercase rounded-full py-0.5 px-2 dark:bg-orange-900/70 dark:border-orange-700 dark:text-orange-500">
                                    Coming soon
                                  </span>
                                </h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-neutral-500">
                                  Stay tuned for more in the future!
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>

                        <p className="mt-4 text-xs text-gray-500 dark:text-neutral-500">
                          Make sure to always check the current{" "}
                          <a
                            className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500"
                            href="##"
                          >
                            status
                          </a>{" "}
                          of our cheats to stay up to date.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-800">
                    <a
                      className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500"
                      href="../docs/index.html"
                    >
                      Installation Guide
                      <svg
                        className="shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="m9 18 6-6-6-6" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-ee-xl bg-gradient-to-tl from-blue-600 to-violet-600 text-gray-800 shadow-md shadow-transparent hover:shadow-blue-700/50 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              disabled=""
            >
              <IconCoinFilled size={18} className="mr-[-0.25rem]" />
              Purchase
            </button>
          </div>
        </div>
        <div className="group flex flex-col h-full bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
          <div className="h-52 flex flex-col justify-center items-center rounded-t-xl">
            <img
              className="h-full object-cover rounded-t-xl"
              src="/assets/eft.jpg"
              alt="Escape from Tarkov"
            />
          </div>
          <div className="p-4 md:p-6">
            <span className="block mb-1 text-xs font-semibold uppercase text-amber-500">
              Coming soon
            </span>
            <h3 className="text-xl font-semibold text-gray-800 dark:text-neutral-300 dark:hover:text-white">
              Escape from Tarkov
            </h3>
            <p className="mt-3 text-gray-500 dark:text-neutral-500">
              Email collaboration and email service desk made easy.
            </p>
          </div>
          <div className="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-neutral-700 dark:divide-neutral-700">
            <button
              className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-es-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-white/10 dark:hover:bg-white/20 dark:border-neutral-700 dark:text-white"
              disabled={true}
            >
              <IconInfoCircleFilled size={18} className="mr-[-0.25rem]" />
              More info
            </button>
            <button
              className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-ee-xl bg-gradient-to-tl from-blue-600 to-violet-600 text-gray-800 shadow-md shadow-transparent hover:shadow-blue-700/50 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              disabled={true}
            >
              <IconCoinFilled size={18} className="mr-[-0.25rem]" />
              Purchase
            </button>
          </div>
        </div>
      </div >
    </div >
  );
};

export default Products;
