const Features = () => {
  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div className="aspect-w-16 aspect-h-7">
        <img
          className="w-full object-cover rounded-xl"
          src="/assets/cs2.jpeg"
          alt="Features"
        />
      </div>
      <div className="mt-5 lg:mt-16 grid lg:grid-cols-3 gap-8 lg:gap-12">
        <div className="lg:col-span-1">
          <h2 className="font-bold text-2xl md:text-3xl text-gray-800 dark:text-neutral-200">
            Enhance your gaming experience with{" "}
            <span className="bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
              eclypse.cc
            </span>
          </h2>
          <p className="mt-2 md:mt-4 text-gray-500 dark:text-neutral-500">
            Unlock your full gaming potential with eclypse.cc! We strive to
            deliver a premium product that can improve your gameplay and give
            you a competitive edge to overcome your opponents.
          </p>
        </div>

        <div className="lg:col-span-2">
          <div className="grid sm:grid-cols-2 gap-8 md:gap-12">
            <div className="flex gap-x-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="shrink-0 mt-1 size-6 text-blue-600 dark:text-blue-500"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="url(#gradient)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <defs>
                  <linearGradient id="gradient" gradientTransform="rotate(45)">
                    <stop offset="0%" stopColor="#2563EB" />{" "}
                    <stop offset="100%" stopColor="#8B5CF6" />{" "}
                  </linearGradient>
                </defs>
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                <path d="M12 12m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
              </svg>
              <div className="grow">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                  Humanized aimbot
                </h3>
                <p className="mt-1 text-gray-600 dark:text-neutral-400">
                  We provide the best aimbot on the market, fully customizable
                  with the highest level of security to ensure you never get
                  banned.
                </p>
              </div>
            </div>
            <div className="flex gap-x-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="shrink-0 mt-1 size-6 text-blue-600 dark:text-blue-500"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="url(#gradient)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <defs>
                  <linearGradient id="gradient" gradientTransform="rotate(45)">
                    <stop offset="0%" stopColor="#2563EB" />{" "}
                    <stop offset="100%" stopColor="#8B5CF6" />{" "}
                  </linearGradient>
                </defs>
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                <path d="M11.102 17.957c-3.204 -.307 -5.904 -2.294 -8.102 -5.957c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6a19.5 19.5 0 0 1 -.663 1.032" />
                <path d="M15 19l2 2l4 -4" />
              </svg>
              <div className="grow">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                  Beautiful visuals
                </h3>
                <p className="mt-1 text-gray-600 dark:text-neutral-400">
                  Experience our simple yet beautiful visuals that make your
                  games more enjoyable while providing you with advantage.
                </p>
              </div>
            </div>
            <div className="flex gap-x-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="shrink-0 mt-1 size-6 text-blue-600 dark:text-blue-500"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="url(#gradient)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <defs>
                  <linearGradient id="gradient" gradientTransform="rotate(45)">
                    <stop offset="0%" stopColor="#2563EB" />{" "}
                    <stop offset="100%" stopColor="#8B5CF6" />{" "}
                  </linearGradient>
                </defs>
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M11.143 20.743a12 12 0 0 1 -7.643 -14.743a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3c.504 1.716 .614 3.505 .343 5.237" />
                <path d="M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
              </svg>
              <div className="grow">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                  Industry-leading security
                </h3>
                <p className="mt-1 text-gray-600 dark:text-neutral-400">
                  Our cheats are built with the highest level of security to
                  ensure protection even against the best anti-cheats on the
                  current market.
                </p>
              </div>
            </div>
            <div className="flex gap-x-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="shrink-0 mt-1 size-6 text-blue-600 dark:text-blue-500"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="url(#gradient)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <defs>
                  <linearGradient id="gradient" gradientTransform="rotate(45)">
                    <stop offset="0%" stopColor="#2563EB" />{" "}
                    <stop offset="100%" stopColor="#8B5CF6" />{" "}
                  </linearGradient>
                </defs>
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 1 0 0 4h2a2 2 0 1 1 0 4h-2a2 2 0 0 1 -1.8 -1" />
                <path d="M12 7v10" />
              </svg>
              <div className="grow">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                  Affordable pricing
                </h3>
                <p className="mt-1 text-gray-600 dark:text-neutral-400">
                  We offer the best and most secure cheats on the market at a
                  ridiculously low price while still providing premium
                  experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
